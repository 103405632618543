.container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 15px;
  height: 100%;
  margin-top: 100px;
  margin-bottom: 150px;
  box-sizing: border-box;
}
.titleWrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
}
.titleContainer{
  display: flex;
  gap: 14px;
  align-items: baseline;
  height: fit-content;
  width: 100%;
}
.title{
  max-width: 751px;
  font-weight: 600;
  font-size: 58px;
  line-height: 62px;
  color: #2F2F2F;
}
.description{
  margin-top: 20px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #646464;
  width: 460px;
  min-width: 100%;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
}
.descriptionButton{
  padding: 10px 0;
  font-weight: 500;
  background: none;
  border: none;
  margin-top: 30px;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #2764FF
}
.contentWrapper{
  width: 100%;
}
.imgWrapper{
  display: flex;
  justify-content: center;
}
.analyticsImg{
  max-width: 715px;
  width: 100%;
  max-height: 376px;
  height: 100%;
}
.ActiveAnalyticsImgWrapper{
  transform: translateY(calc(+6%));
  opacity: 0;
  transition: all 0.7s ease;
}
.AnalyticsImgWrapper{
  opacity: 1;
  transition: all 2s ease;
}
.descriptionWrapper{
  max-width: 600px;
  width: 100%;
}
.contentContainer{
  display: flex;
  justify-content: space-between;
  gap: 20px;
  min-height: 400px;
}
.descriptionTitle{
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #000000;
}
.dashboard{
  position: relative;
  padding-right: 30px;
  height: fit-content;
}
.nextArrow{
  cursor: pointer;
  position: absolute;
  bottom: 5px;
  right: 0;
  height: 45%;
}
.nextArrow > path {
  fill: #4E76D1;
}
.prevArrow{
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 0;
  height: 45%;
}
.prevArrow > path{
  fill: #4E76D1;
}
.disabledArrow > path{
  fill: #7E7B78;
}
@media (max-width: 1500px) {
  .container{
    max-width: 1200px;
  }
  .descriptionWrapper{
    max-width: 480px;
  }
  .contentContainer{
    gap: 20px;
  }
}
@media(max-width: 1200px){
  .title{
    font-size: 40px;
    line-height: 48px;
  }
  .descriptionWrapper{
    max-width: 540px;
  }
  .descriptionTitle{
    font-size: 22px;
    line-height: 28px;
  }
  .description{
    font-size: 16px;
    line-height: 22px;
  }
  .contentContainer{
    gap: 70px;
  }
}
@media (max-width: 1080px) {
  .container{
    display: none;
  }
}