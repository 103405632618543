.fourthBlock {
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  background: #FFFFFF;
}

.container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 15px;
  height: 100%;
  margin-top: 100px;
  box-sizing: border-box;
}
.titleContainer{
  display: flex;
  gap: 14px;
  align-items: baseline;
  height: fit-content;
  margin-bottom: 64px;
}
.title{
  font-weight: 600;
  font-size: 58px;
  color: #2F2F2F;
}
.subTitle{
  font-weight: 500;
  font-size: 28px;
  color: #2F2F2F;
  margin-top: 20px; 
}
.button{
  margin-top: 52px;
  background: #2764FF;
  padding: 10px 32px;
  border: none;
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s;
}
.button:hover{
  opacity: 0.7;
  transition: all 0.2s;
}
@media (max-width: 1500px) {
  .container{
    max-width: 1200px;
  }
}
@media (max-width: 685px){
  .titleContainer{
    flex-wrap: wrap;
  }
  .container{
    margin-top: 60px;
    padding: 0 20px;
  }
  .title{
    margin-top: 40px;
    font-size: 40px;
  }
  .subTitle{
    font-size: 16px;
  }
  .button{
    margin-top: 42px;
  }
}