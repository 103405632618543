.container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 15px;
  height: 100%;
  margin-top: 100px;
  margin-bottom: 109px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.titleContainer{
  display: flex;
  gap: 14px;
  align-items: baseline;
  height: fit-content;
  margin-bottom: 64px;
}
.title{
  font-weight: 600;
  font-size: 54px;
  color: #2F2F2F;
}
.description{
  margin-top: 21px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #646464;
  max-width: 460px;
  width: 100%;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
}
.contentWrapper{
  position: relative;
  width: 44%;
}
.bg{
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 1;
  width: 100%;
  background: rgba(21, 143, 255, 0.14);
  filter: blur(150px);
}
.mobile{
  display: none;
}
.svg{
  position: relative;
  z-index: 10 !important;
}
@media (max-width: 1500px) {
  .container{
    max-width: 1200px;
  }
}

@media (max-width: 1180px) {
  .title{
    font-size: 40px;
  }
  .description{
    font-size: 16px;
  }
}

@media (max-width: 1050px) {
  .container{
    flex-direction: column;
  }
  .contentWrapper{
    align-self: center;
  }
  .description{
    max-width: 80%;
  }
  .contentWrapper{
    width: 70%;
  }
}
@media (max-width: 675px) {
  .titleContainer{
    flex-direction: column;
    gap: 40px;
  }
  .description{
    max-width: 100%;
  }
  .contentWrapper{
    width: 100%;
  }
  .container{
    margin-top: 40px;
    padding: 0 20px;
  }
  .title > br:nth-child(1){
    display: none;
  }
  .mobile{
    display: block;
  }
  .desktop{
    display: none;
  }
}
