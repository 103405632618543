.headerWrapper{
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(2.5px);
  position: fixed;
  z-index: 20;
  width: 100%;
}
.header{
  display: flex;
  justify-content: space-between;
  max-width: 1600px;
  align-items: center;
  margin-bottom: 15px !important;
  margin-top: 10px !important;
}
.container{
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  height: 100%;
  display: flex;
  box-sizing: border-box;
}
.buttons{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 66px;
}
.react-select-container{
  border: none;
  cursor: pointer;
  /* border: none;
  background: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 24px;
  color: #2F2F2F;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("../../assets/pictures/arrow.svg");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 9px;
  width: 46px;
  padding-bottom: 2.5px; */
}
.select{
  font-size: 24px;
  border: none;
  background: transparent;
  
}
.react-select-container{
  font-size: 24px;
  background-color: transparent !important;
}

.react-select-control{
  background-color: transparent;
}
.selectImg{
  padding-right: 5px;
}
.button{
  height: fit-content;
  min-width: fit-content;
  font-weight: 500;
  font-size: 18px;
  color: #2F2F2F;
  border: none;
  background: none;
  cursor: pointer;
  text-transform: uppercase;
}

.buttonSignup {
  height: fit-content;
  min-width: fit-content;
  font-weight: 500;
  font-size: 18px;
  border: none;
  background: #fff27D;
  cursor: pointer;
  text-transform: uppercase;
  color: #2F2F2F;
  /* border: 1px solid rgba(103, 146, 255, 0.5); */
  border-radius: 4px;
  padding: 10px;

  transition: all 0.3s ease;
}

.buttonSignup:hover {
  background-color: #fffbd4; 
  color: #2F2F2F;
}

.buttonSignupp:active {
  background-color: #0D2972; 

  transform: translateY(2px);
}


.buttonContact{
  height: fit-content;
  font-weight: 500;
  font-size: 18px;
  color: #2F2F2F;
  border: none;
  background: none;
  cursor: pointer;
  min-width: fit-content;
  text-transform: uppercase;
}
.flag{
  height: 24px;
  width: 32px;
}
.login{
  display: flex;
  align-items: center;
}
.border{
  border: 0.5px solid #2F2F2F;
  height: 18px;
  margin: 0 18px 0 18px;
}

@media (max-width: 1500px) {
  .header{
    max-width: 1200px;
  }
}
@media (max-width: 550px) {
  .login{
    display: flex;
    width: 120px;
    flex-wrap: wrap;
    justify-content: end;
  }
  .button{
    font-size: 12px;
  }

  .border {
    height: 14px;
    margin: 0;
  }

  .react-select-container {
    display: none;
  }
}


@media (max-width: 1200px) {
  .container{
    /* max-width: 330px; */
  }
  .buttonContact{
    display: none;
  }
  .logo > img{
    width: 118px;
    height: 42px;
  }
  .button{
    font-size: 12px;
  }

  .buttonSignup {
    font-size: 10px;
    padding: 8px;
  }

  .react-select-container {
    font-size: 12px;
  }
  
  /* .border{
    margin: 0;
  } */
  .buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0px;
    margin-left: auto;
  }
}