.fifthBlock {
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 100%;
  background: #0D2972;
}

.container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 15px;
  height: 100%;
  margin-top: 100px;
  padding-bottom: 58px;
  box-sizing: border-box;
}
.titleContainer{
  display: flex;
  gap: 14px;
  align-items: baseline;
  height: fit-content;
  margin-bottom: 64px;
}
.title{
  font-weight: 600;
  font-size: 58px;
  color: #FFFFFF;
}
.inputWrapper{
  margin-top: 29px;
}
.inputName{
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 20px;
  color: #E7E7E7;
}
.input{
  width: 400px;
  border: 1px solid #ACB5CC;
  background: transparent;
  padding: 10px 20px;
  color: #E7E7E7;
  font-weight: 300;
  font-size: 18px;  
}
.input::placeholder{
  color: #E7E7E7;
}
.button{
  margin-top: 40px;
  background: #FFFFFF;
  padding: 10px 32px;
  font-weight: 500;
  font-size: 18px;
  color: #2764FF;
  text-transform: uppercase;
  border: none;
  transition: all 0.2s;
  cursor: pointer;
}
.button:hover{
  opacity: 0.7;
  transition: all 0.2s;
}
.rights{
  margin-top: 16px;
  font-weight: 300;
  font-size: 14px;
  color: #E7E7E7;
}
.errorInput {
  border-color: #ff4d4f !important;
}

.errorText {
  color: #ff4d4f;
  font-size: 14px;
  margin-top: 4px;
}

.button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Add these to your existing media queries */
@media (max-width: 685px) {
  .errorText {
    font-size: 12px;
  }
}



@media (max-width: 1500px) {
  .container{
    max-width: 1200px;
  }
}
@media (max-width: 685px){
  .container{
    padding: 0 20px;
    margin-top: 40px;
  }
  .button{
    color: #FFFFFF;
    background: transparent;
    border: 1px solid #FFFFFF;
  }
  .titleContainer{
    flex-wrap: wrap;
  }
  .inputName{
    font-size: 18px;
  }
  .contactWrapper{
    width: 100%;
  }
  .input{
    font-size: 18px;
    width: 100%;
    box-sizing: border-box;
  }
  .rights{
    margin-top: 12px;
    margin-bottom: 16px;
  }
}