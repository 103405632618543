.customers {
  background-color: #0D2972;
  /* background-color: #ebf5f9; */
  /* margin-bottom: 40px;
  margin-top: 40px; */
}

.container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 25px 15px;
  /* height: 50%; */
  box-sizing: border-box;
}

.customersList {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media (max-width: 950px) {
  .customersList {
    /* flex-wrap: wrap; */
    gap: 5px;
  }
}

.logoWrapper {
  width: 160px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

@media (max-width: 950px) {
  .logoWrapper {
    width: 100px;
    height: 40px;
  }

  .container {
    padding: 0 15px;
  }
}

@media (max-width: 650px) {
  .logoWrapper {
    width: 80px;
    height: 40px;
  }
}

@media (max-width: 550px) {
  .logoWrapper {
    width: 60px;
    height: 30px;
  }
}

.logo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  transition: transform 0.5s ease;
}

.logo:hover {
  transform: scale(1.1);
}
