/* .footer {
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  background: #0D2972;
} */
.container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  height: 100%;
  padding: 50px 15px 51px 15px;
  border-top: 1px solid #354C84;
  border-bottom: 1px solid #354C84;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}
.linksWrapper{
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px
}
.logoWrapper{
  max-width: 400px;
}
.logo{
  margin-bottom: 24px;
}
.subTitle{
  font-weight: 400;
  font-size: 20px;
  color: #E7E7E7;
}
.link{
  font-weight: 400;
  font-size: 20px;
  color: #FFFFFF;
  margin-bottom: 32px;
  cursor: pointer;
}
.link:hover{
  opacity: 0.7;
  transition: all 0.2s;
}
.link:last-child{
  margin-bottom: 0px;
}
.button{
  padding: 10px 32px;
  background: #2764FF;
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  border: none;
  color: #FFFFFF;
  transition: all 0.2s;
  cursor: pointer;
}
.button:hover{
  opacity: 0.7;
  transition: all 0.2s;
}
@media (max-width: 1500px) {
  .container{
    max-width: 1200px;
  }
}
@media(max-width:685px){
  .container{
    display: none
  }
}