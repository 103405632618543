/* .footer {
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  background: #0D2972;
} */
.container{
  display: none
}
@media(max-width: 685px){
  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
    padding: 40px 0px 40px 0px;
    border-top: 1px solid #354C84;
    border-bottom: 1px solid #354C84;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
  }
  .linksWrapper{
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 5px;
    margin-top: 40px;
  }
  .logoWrapper{
    border-bottom: 1px solid #354C84;
    padding-bottom: 40px;
  }
  .logo{
    margin-bottom: 12px;
  }
  .subTitle{
    max-width: 298px;
    font-weight: 400;
    font-size: 16px;
    color: #E7E7E7;
  }
  .border{
    border: 0.5px solid #FFFFFF;
    height: 18px;
    margin: 0 18px 0 18px;
  }
  .languages{
    display: flex;
  }
  .language{
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    cursor: pointer;
  }
  .activeLanguage{
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    cursor: pointer;
    text-decoration: underline;
  }
  .link{
    font-weight: 400;
    min-width: fit-content;
    font-size: 20px;
    color: #FFFFFF;
    margin-bottom: 32px;
    cursor: pointer;
  }
  .link:hover{
    opacity: 0.7;
    transition: all 0.2s;
  }
  .link:last-child{
    margin-bottom: 0px;
  }
  .button{
    padding: 10px 32px;
    margin-top: 40px;
    background: #2764FF;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    border: none;
    color: #FFFFFF;
    transition: all 0.2s;
    cursor: pointer;
  }
  .button:hover{
    opacity: 0.7;
    transition: all 0.2s;
  }
}
