.path {
  fill:none;
  stroke:#2764FF;
  stroke-dasharray: 3;
  stroke-linecap: square;
  animation: dash 3s linear infinite;
}
.svg{
  position: relative;
  z-index: 10 !important;
}
@keyframes dash {
  from {
    stroke-dashoffset: 20;
  }
  to {
    stroke-dashoffset: 0;
  }
}