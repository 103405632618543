.firstBlock {
  width: 100%;
  height: 87vh;
  overflow: hidden;

  position: relative;
}

.video{
  display: block;

  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  bottom: 0;
  left: 0;

}

.container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 15px;
  height: 100%;
  display: flex;
  box-sizing: border-box;
}

.textContainer {
  position: absolute;
  bottom: 10%;
  align-self: end;
  margin-bottom: 50px;
}

.Title {
  font-weight: 700;
  font-size: 84px;
  color: #ffffff;
}

.subTitle {
  font-weight: 500;
  font-size: 28px;
  color: #ffffff;
}
@media (max-width: 1500px) {
  .container{
    max-width: 1200px;
  }
}
@media (max-width: 1200px) {
  .video{
    height: 100%;
  }
}
@media (max-width: 685px) {
  .firstBlock{
    background-image: url(../../assets/pictures/BackgroundVideo.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: -280px;
    height: 93vh;
  }

  .textContainer {
    bottom: 10%;
  }
  .video{
    display: none;
  }
  .Title{
    font-size: 40px;
    margin-bottom: 8px;
  }
  .subTitle{
    font-size: 16px;
  }
}

@media (min-width: 1500px) {
  video{
    /* width: 100%; */
  }
}