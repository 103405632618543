.react-select-container{
  font-size: 24px;
  background-color: transparent;
  cursor: pointer !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important
}
.react-select-container:focus-visible{
  outline: none !important;
}
.react-select__control{
  background-color: transparent !important;
  border: none !important;
  cursor: pointer !important;
  box-shadow: none !important;
  border: none !important;

  /* display: flex !important;
  align-items: center !important;
  justify-content: center !important; */
}

.react-select__value-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding-left: 0 !important;
}

/* .selectImg {
  margin-left: auto; 
  padding-right: 5px;
} */


.react-select__control:hover{
  background-color: transparent !important;
  border: none !important;
  cursor: pointer !important;
  box-shadow: none !important;
}
.react-select__menu {
  background-color: transparent !important;
  cursor: pointer !important;
}
.react-select__option--is-selected{
  padding: 0px 12px !important;
  background-color: transparent !important;
  cursor: pointer !important;
}
.react-select__option{
  padding: 0px 12px !important;
  cursor: pointer !important;
}
.react-select__option--is-focused{
  background-color: #3D64FF !important;
  cursor: pointer !important;
}

/* @media (max-width: 940px) {
  .react-select-container{ 
    display: none;
  }
} */

@media (max-width: 940px) {
  .react-select-container{ 
    font-size: 22px;
  }
}

@media (max-width: 550px) {
  .react-select-container{ 
    display: none;
  }
}