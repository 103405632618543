.container{
  display: none;
}

@media (max-width: 1080px) {
  .container {
    display: block;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    height: 100%;
    margin-top: 40px;
    box-sizing: border-box;
  }
  .title{
    max-width: 751px;
    font-weight: 600;
    font-size: 40px;
    line-height: 62px;
    color: #2F2F2F;
  }
  .titleWrapper{
    display: flex;
    gap: 14px;
    align-items: center;
  }
  .dashboardWrapper{
    margin-top: 52px;
    margin-bottom: 62px;
  }
  .dashboardTitle{
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #000000;
    margin-bottom: 20px;
  }
  .dashboardDescription{
    margin-top: 20px;
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #646464;
  }
  .dashboardCredo{
    margin-top: 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #2764FF;
  }
  .dashboardImg{
    text-align: center;
  }
}
@media(max-width: 675px){
  .dashboardWrapper{
    margin-top: 21px;
  }
  .titleWrapper{
    flex-direction: column;
    gap: 40px;
    align-items: baseline;
  }
  .container{
    padding: 0 20px;
  }
  .dashboardImg > img{
    width: 100%;
    height: 100%;
  }
}