.reviewsBlock {
  overflow: hidden;
  background: #ffffff;
  width: 100%;
  height: 100%;
  min-height: 100%;
  /* padding-bottom: 135px; */
}
.container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 15px;
  height: 100%;
  box-sizing: border-box;
  padding-top: 110px;
}
.titleContainer {
  display: flex;
  gap: 14px;
  align-items: baseline;
  height: fit-content;
  margin-bottom: 64px;
}
.title {
  margin: 0 !important;
  padding: 0 !important;
  font-weight: 600;
  font-size: 58px;
}
.contentWrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.card {
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 500px;
  border: 1px solid #0d2972;
  padding: 32px;
  transition: all 0.5s ease;
}

.author {
  color: #646464;

  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.position {
  font-size: 16px;
  color: #666;
}

.activePosition {
  font-size: 14px;
  color: #666;
}

.activeAuthor {
  color: #646464;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.cardSubTitle {
  /* height: 25%; */
  font-weight: 400;
  font-size: 20px;
  color: #646464;
  opacity: 0;
  transition-duration: 0.1s;
  display: none;
}

.cardTitle {
  /* font-weight: 400;
  font-size: 32px; */
}

/* .authorWrapper {
  height: 25%;
  font-weight: 500;
  font-size: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
} */

/* .activeAuthorWrapper {
  height: 25%;
  font-weight: 500;
  font-size: 22px;
} */

.authorBlock {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.activeCardTitle {
  /* margin-top: 50px; */
  /* height: 25%; */
  font-weight: 500;
  font-size: 22px;
  /* color: #FFFFFF; */
  display: flex;
  /* flex-direction: column; */
  align-items: end;
  /* align-self: start; */
  -webkit-animation: fadeInFromNone 1s ease-out;
  -moz-animation: fadeInFromNone 1s ease-out;
  -o-animation: fadeInFromNone 1s ease-out;
  animation: fadeInFromNone 1s ease-out;
  transition: all 1s ease;

  position: absolute;
  bottom: 5%;
  left: 35%;
}

.activeCardSubTitle {
  padding-top: 8vw;
  /* height: 25%; */
  font-weight: 400;
  font-size: 20px;
  color: #646464;
  opacity: 1;
  transition-delay: 0.6s;
  transition-duration: 0.4s;
}

.activeCard {
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start; */
  align-items: center;
  width: 50%;
  height: 500px;
  border: 1px solid #0d2972;
  padding: 32px;
  -webkit-animation: fadeInFromNone 1s ease-out;
  -moz-animation: fadeInFromNone 1s ease-out;
  -o-animation: fadeInFromNone 1s ease-out;
  animation: fadeInFromNone 1s ease-out;
  transition: all 1s ease;

  position: relative;
}

.activeCardLogo {
  height: 50%;
  align-self: flex-start;
  transition-delay: 1s;
  transition-duration: 1s;
}
/* .responsiveCardLogo{
  display: none;
} */
.authorWrapper {
  display: none;
}
/* .responsiveCardSubTitle{
  display: none;
} */

/*  */
/* @media (max-width: 845px) {
  .title{
    font-size: 40px;
    width:230px; 
    margin-top: 40px;
  }
  .container{
    margin-top: 40px;
    padding: 0 20px;
  }
  .titleContainer{
    flex-wrap: wrap;
    margin-bottom: 32px;
  }
  .contentWrapper{
    flex-wrap: wrap;
  }
} */

@media (max-width: 1230px) {
  .activeCardSubTitle {
    padding-top: 6vw;
    font-weight: 400;
    font-size: 16px;
  }

  .author {
    font-size: 14px;
    font-weight: bold;
  }
  .position {
    font-size: 14px;
  }
}

@media (max-width: 1080px) {
  .author {
    font-size: 14px;
    font-weight: bold;
  }
  .position {
    font-size: 14px;
  }
  .activeCardSubTitle {
    /* height: 25%; */
    font-weight: 400;
    font-size: 16px;
  }
}

@media (max-width: 980px) {
  .author {
    font-size: 12px;
    font-weight: bold;
  }
  .position {
    font-size: 12px;
  }

  .activePosition {
    font-size: 12px;
  }

  .activeAuthor {
    font-size: 12px;
  }
}

@media (max-width: 845px) {
  .title {
    font-size: 40px;
    width: 230px;
    margin-top: 40px;
  }

  .container {
    margin-top: 40px;
    padding: 0 20px;
  }

  .titleContainer {
    flex-wrap: wrap;
    margin-bottom: 32px;
  }

  .contentWrapper {
    flex-direction: column;
    gap: 20px;
  }

  .card,
  .activeCard {
    width: 100%;
    padding: 32px;
    height: auto;
    min-height: 300px;
    transition: none;
    animation: none;
    justify-content: space-between;
    box-sizing: border-box;
    position: static;
  }

  .cardSubTitle,
  .activeCardSubTitle {
    opacity: 1;
    height: auto;
    transition: none;
    display: block;
  }

  .activeCardTitle,
  .cardTitle {
    position: static;
    margin-top: 20px;
    animation: none;
    transition: none;
  }

  .author,
  .activeAuthor {
    font-size: 16px;
  }

  .position,
  .activePosition {
    font-size: 14px;
  }
}

/* @media (max-width: 488px) {
  .responsiveCardSubTitle{
    padding-bottom: 130px;
  }
  .card{
    min-height: 450px;

  }
  .activeCard{
    min-height: 450px;
  }
} */

@-webkit-keyframes fadeInFromNone {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  1% {
    visibility: visible;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

@-moz-keyframes fadeInFromNone {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  1% {
    visibility: visible;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

@-o-keyframes fadeInFromNone {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  1% {
    visibility: visible;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fadeInFromNone {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  1% {
    visibility: visible;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}
