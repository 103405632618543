.secondBlock {
  overflow: hidden;
  background-image: url(../../assets/pictures/Bg2.png);
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-bottom: 60px;
}

.container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 15px;
  height: 100%;
  margin-top: 100px;
  box-sizing: border-box;
}
.contentWrapper{
  display: flex;
  gap: 40px;
  justify-content: space-between;
}
.screensImg{
  width: 610px;
  height: 571px;
}
.companiesWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.titleContainer{
  display: flex;
  gap: 14px;
  align-items: center;
  height: fit-content;
  margin-bottom: 64px;
}
.title{
  font-weight: 600;
  font-size: 58px;
  color: #2F2F2F;
}
.liWrapper{
  margin-bottom: 40px;
  display: flex;
  gap: 20px;
}
.liTitle{
  font-weight: 500;
  font-size: 22px;
  color: #2F2F2F;
}
.liSubTitle{
  font-weight: 400;
  font-size: 20px;
  color: #2F2F2F;
}
.companies{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 580px;
}
.companiesImg{
  margin-right: 10px;
  margin-bottom: 10px;
}
.companiesText{
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 22px;
  color: #2F2F2F;
}
.responsivecompaniesText{
  display: none;
}
@media (max-width: 1500px) {
  .container{
    max-width: 1200px;
  }
}
@media (max-width: 950px) {
  .contentWrapper{
    flex-wrap: wrap;
  }
}
@media (max-width: 685px) {
  .secondBlock{
    background-size: 1400px 100%;
    background-position: center;
  }
  .container{
    margin-top: 60px;
    padding: 0 20px;
  }
  .titleContainer{
    flex-wrap: wrap;
  }
  .title{
    font-size: 40px;
    margin-top: 20px;
  }
  .companiesText{
    display: none;
  }
  .responsivecompaniesText{
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 22px;
    color: #2F2F2F;
    margin-top: 63px;
    margin-bottom: 19px;
  }
  .companiesImg{
    width: 98px;
    height: 98px;
  }
  .titleContainer{
    margin-bottom: 40px;
  }
  .screensImg{
    width: 100%;
    height: auto;
  }
  .liSubTitle{
    font-size: 16px;
  }
}
