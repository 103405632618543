.thirdBlock {
  overflow: hidden;
  background: #0D2972;
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding-bottom: 135px; 
}
.container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 15px;
  height: 100%;
  box-sizing: border-box;
  margin-top: 100px;
}
.titleContainer{
  display: flex;
  gap: 14px;
  align-items: baseline;
  height: fit-content;
  margin-bottom: 64px;
}
.title{
  font-weight: 600;
  font-size: 58px;
  color: #FFFFFF;
}
.contentWrapper{
  display: flex;
  justify-content: space-between;
  gap: 20px;

}
.card{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 25%;
  height: 500px;
  border: 1px solid #FFFFFF;
  padding: 32px;
  transition: all 0.5s ease;
}
.cardLogo{
  height: 50%;
  display: flex;
  align-items: end;
  transition: all .6s ease;
  opacity: 1
}
.cardSubTitle{
  height: 25%;
  font-weight: 400;
  font-size: 20px;
  color: #E7E7E7;
  opacity: 0;
  transition-duration: 0.1s;
}
.cardTitle{
  height: 25%;
  font-weight: 500;
  font-size: 22px;
  color: #FFFFFF;
  display: flex;
  align-items: end;
  align-self: start;
  -webkit-animation: fadeInFromNone 1s ease-out;
  -moz-animation: fadeInFromNone 1s ease-out;
  -o-animation: fadeInFromNone 1s ease-out;
  animation: fadeInFromNone 1s ease-out;
  transition: all 1s ease;
}
.activeCardSubTitle{
  height: 25%;
  font-weight: 400;
  font-size: 20px;
  color: #E7E7E7;
  opacity: 1;
  transition-delay: 0.6s;
  transition-duration: 0.4s;
}
.activeCard{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  height: 500px;
  border: 1px solid #FFFFFF;
  padding: 32px;
  -webkit-animation: fadeInFromNone 1s ease-out;
  -moz-animation: fadeInFromNone 1s ease-out;
  -o-animation: fadeInFromNone 1s ease-out;
  animation: fadeInFromNone 1s ease-out;
  transition: all 1s ease;
}
.activeCardLogo{
  height: 50%;
  align-self: flex-start;
  transition-delay: 1s;
  transition-duration: 1s;
}
.responsiveCardLogo{
  display: none;
}
.responsivecardTitle{
  display: none;
}
.responsiveCardSubTitle{
  display: none;
}
@media (max-width: 1500px) {
  .container{
    max-width: 1200px;
  }
}
@media (max-width: 1493px) {
  .card{
    height: 600px;
  }
  .activeCard{
    height: 600px;
  }
  .activeCardSubTitle{
    padding-bottom: 100px;
  }
}
@media (max-width: 945px) {
  .activeCardSubTitle{
    padding-bottom: 200px;
  }
}
@media (max-width: 845px) {
  .title{
    font-size: 40px;
    width:230px; 
    margin-top: 40px;
  }
  .container{
    margin-top: 40px;
    padding: 0 20px;
  }
  .titleContainer{
    flex-wrap: wrap;
    margin-bottom: 32px;
  }
  .contentWrapper{
    flex-wrap: wrap;
  }
  .responsiveCardLogo{
    display: flex;
    height: 35%;
    align-self: flex-start;
    transition-delay: 1s;
    transition-duration: 1s;
  }
  .responsiveCardLogo > img{
    height: 110px;
  }
  .responsivecardTitle{
    font-weight: 500;
    font-size: 22px;
    color: #FFFFFF;
    display: flex;
    align-items: end;
    align-self: start;
    margin-bottom: 32px;

  }
  .responsiveCardSubTitle{
    height: 25%;
    font-weight: 400;
    font-size: 16px;
    color: #E7E7E7;
    opacity: 1;
    transition-delay: 0.6s;
    transition-duration: 0.4s;
    display: flex;
  }
  .activeCard{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-height: 280px;
    max-height: 370px;
    border: 1px solid #FFFFFF;
    padding: 32px;
    -webkit-animation: none;
    -moz-animation: none;
    -o-animation: none;
  }
  .card{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-height: 280px;
    max-height: 370px;
    border: 1px solid #FFFFFF;
    padding: 32px;
    transition: all 1s ease;
  }
  .cardSubTitle{
    display: none;
  }
  .activeCardSubTitle{
    display: none;
  }
  .activeCardLogo{
    display: none;
  }
  .cardLogo{
    height: 50%;
    display: block;
    align-self: flex-start;
    transition-delay: 1s;
    transition-duration: 1s;
    margin-bottom: 33px;
    display: none;
  }
  .cardTitle{
    height: fit-content;
    display: none;
  }
}


@media (max-width: 488px) {
  .responsiveCardSubTitle{
    padding-bottom: 130px;
  }
  .card{
    min-height: 450px;

  }
  .activeCard{
    min-height: 450px;
  }
}



@-webkit-keyframes fadeInFromNone {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  1% {
    visibility: visible;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

@-moz-keyframes fadeInFromNone {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  1% {
    visibility: visible;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

@-o-keyframes fadeInFromNone {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  1% {
    visibility: visible;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fadeInFromNone {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  1% {
    visibility: visible;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}
